import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const Start = () => {
  return (
    <div className="section section-padding infographics-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="acr-infographic-item">
              <i className="flaticon-telephone"></i>
              <div className="acr-infographic-item-body">
                <h5>Reach Out</h5>
                <p>
                  A bicycle-sharing system public bicycle scheme or public bike
                  share PBS scheme is a shared transport .
                </p>
                <OutboundLink
                  href="tel:239-676-0766"
                  className="btn-custom secondary btn-sm"
                >
                  Call Us
                </OutboundLink>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="acr-infographic-item">
              <i className="flaticon-location"></i>
              <div className="acr-infographic-item-body">
                <h5>Find Us</h5>
                <p>
                  A bicycle-sharing system public bicycle scheme or public bike
                  share PBS scheme is a shared transport .
                </p>
                <OutboundLink href="#" className="btn-custom secondary btn-sm">
                  Get Directions
                </OutboundLink>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="acr-infographic-item">
              <i className="flaticon-speech-bubble"></i>
              <div className="acr-infographic-item-body">
                <h5>Get Support</h5>
                <p>
                  A bicycle-sharing system public bicycle scheme or public bike
                  share PBS scheme is a shared transport .
                </p>
                <a href="#" className="btn-custom secondary btn-sm">
                  Start Chat
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Start;
