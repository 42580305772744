import React from "react";
import Layout from "@/components/Layout";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Contact from "@/components/Contact";
import { useLocation } from "@reach/router";
import SEO from "@/components/seo";

const Services = () => {
  return (
    <Layout>
      <SEO title="Contact" canonical={useLocation().host} />
      <Header />
      <Contact />
      <Footer />
    </Layout>
  );
};

export default Services;
