import React, { useMemo, useRef } from 'react';
import { FormProvider } from '@/hook-form';
import RHInput from '@/hook-form/RHInput';
import RHTextarea from '@/hook-form/RHTextarea';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { saveContact, ContactData } from '@/npc-api/contact';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import ReCAPTCHA from 'react-google-recaptcha';
import toast from 'react-hot-toast';
import { useConfig } from '@/hooks/useConfig';
import { GTM } from '@/utils/google-tag-manager';
import { GTMEventType } from '@/types/google-tag-manager.interface';

const ContactForm = () => {
  const { captchaSiteKey } = useConfig();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const ContactSchema = Yup.object().shape({
    name: Yup.string().max(50).required('Name is required'),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    email: Yup.string().required('Email is required').email(),
    company: Yup.string(),
    message: Yup.string().required('Message is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: '',
      phone: '',
      email: '',
      company: '',
      message: '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm<ContactData>({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: ContactData) => {
    if (!recaptchaRef.current?.getValue()) {
      toast.error(
        'Please check the Captcha field to validate you are not a robot'
      );
      return;
    }

    GTM.sendEvent({
      event: GTMEventType.RequestContact,
    });

    await saveContact(data).then((e) =>
      reset({ name: '', phone: '', email: '', company: '', message: '' })
    );
    recaptchaRef.current?.reset();
  };
  return (
    <div className="section pt-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mb-lg-30">
            <div
              className="acr-locations bg-bottom bg-norepeat"
              //   style="background-image: url('images/misc/bldg.png')"
              style={{ backgroundImage: "url('images/misc/bldg.png')" }}
            >
              <img src="images/contact.jpg" alt="" />
              <div className="row">
                <div className="col-sm-6">
                  <div className="acr-location">
                    <h5>Florida</h5>
                    <h5>USA</h5>
                    <div className="acr-location-address">
                      <p>20041 S Tamiami Trl STE 7 Estero, FL 33928-2227</p>
                      <OutboundLink href="tel:239-676-0766">
                        239-676-0766
                      </OutboundLink>
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-6">
                  <div className="acr-location">
                    <h5>Tbilsi</h5>
                    <h5>Georgia</h5>
                    <div className="acr-location-address">
                      <p>Aaron Hawkins 5587 Nunc. Avenue</p>
                      <a href="tel:+123456789">(134) 984 438</a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="acr-location">
                    <h5>Moscow</h5>
                    <h5>Russia</h5>
                    <div className="acr-location-address">
                      <p>Lawrence Moreno 935-9940 Tortor. Street</p>
                      <a href="tel:+123456789">(443) 893 109</a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="acr-location">
                    <h5>Cairo</h5>
                    <h5>Egypt</h5>
                    <div className="acr-location-address">
                      <p>Aaron Hawkins 5587 Nunc. Avenue</p>
                      <a href="tel:+123456789">(009) 338 148</a>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col">
                  <div className="container-iframe">
                    {' '}
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3572.227786989454!2d-81.81836428544023!3d26.448385483329705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88db168c6dd8be2b%3A0xe5d1b31921829172!2s20041%20S%20Tamiami%20Trail%20%237%2C%20Estero%2C%20FL%2033928!5e0!3m2!1ses-419!2sus!4v1655133629869!5m2!1ses-419!2sus"
                      style={{ border: 0 }}
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      className="responsive-iframe"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="section-title-wrap section-header" id="question">
              <h5 className="custom-primary">Contact Us</h5>
              <h2 className="title">Got Any Questions?</h2>
            </div>

            <div className="comment-form">
              <FormProvider
                name="fwadedamsn"
                methods={methods}
                onSubmit={handleSubmit(onSubmit)}
                className="mf_form_validate ajax_submit"
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label>Full Name</label>
                    <RHInput
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Your Name"
                      required={true}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Email</label>
                    <RHInput
                      name="email"
                      type="text"
                      className="form-control"
                      placeholder="Your Email"
                      required={true}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Phone Number</label>
                    <RHInput
                      name="phone"
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      required={true}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Company</label>
                    <RHInput
                      name="company"
                      type="text"
                      className="form-control"
                      placeholder="Company"
                    />
                  </div>
                  <div className="col-md-12 form-group">
                    <label>Your Message</label>
                    <RHTextarea
                      className="form-control"
                      name="message"
                      placeholder="Your Message"
                      rows={7}
                    />
                  </div>
                </div>

                {captchaSiteKey && (
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <ReCAPTCHA
                        sitekey={captchaSiteKey}
                        ref={recaptchaRef}
                        size="normal"
                      />
                    </div>
                  </div>
                )}

                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="btn-custom primary"
                  name="button"
                >
                  {isSubmitting ? '...Loading' : 'Send Message'}
                </button>
                <div className="server_response"></div>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
