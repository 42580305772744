import React from "react";
import Header from "@/components/Header/Header";
import SubHeader from "@/components/About/SubHeader";
import Start from "@/components/Contact/Start";
import ContactForm from "@/components/Contact/ContactForm";

const Contact = () => {
  return (
    <>
      <Header />
      <SubHeader title="Contact Us" />
      <Start/>
      <ContactForm/>
    </>
  );
};

export default Contact;
